import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/templates/page.jsx";
import { Social } from '../components/Social';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "mailto:info@ppke.sk"
          }}>{`info@ppke.sk`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`event management - `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "mailto:events.ppke@proton.me"
          }}>{`events.ppke@proton.me`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="h2" components={components}>{`Dokumenty`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/ParalelnaPolisKE/docs/tree/master/guidelines"
        }}>{`Sprievodca pre prednášky a cenník za prenájom`}</MDXTag></MDXTag>
      <MDXTag name="h2" components={components}>{`Sociálne siete`}</MDXTag>
      <Social />
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      